var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        "close-on-click-modal": false,
        width: "50%",
        top: "7vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [
          _c("i"),
          _c("span", { domProps: { textContent: _vm._s(_vm.StuDialogTitle) } }),
        ]
      ),
      _c(
        "el-form",
        {
          ref: "formData",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                { attrs: { label: "用户名：", prop: "loginName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入用户名" },
                    model: {
                      value: _vm.formData.loginName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "loginName", $$v)
                      },
                      expression: "formData.loginName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "姓名：", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入姓名" },
                    model: {
                      value: _vm.formData.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "name", $$v)
                      },
                      expression: "formData.name",
                    },
                  }),
                ],
                1
              ),
              _vm.staffData === null
                ? [
                    _c(
                      "el-form-item",
                      { attrs: { label: "初始密码：", prop: "pwd" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder:
                              "请输入初始密码（密码包含大小写字母、特殊符号、数字三种即可）",
                            "show-password": "",
                            "auto-complete": "new-password",
                          },
                          model: {
                            value: _vm.formData.pwd,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "pwd", $$v)
                            },
                            expression: "formData.pwd",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                : [
                    _c(
                      "el-form-item",
                      { attrs: { label: "修改密码：" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入新密码、为空密码不变",
                            "show-password": "",
                            "auto-complete": "new-password",
                          },
                          model: {
                            value: _vm.formData.pwd,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "pwd", $$v)
                            },
                            expression: "formData.pwd",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
              _c(
                "el-form-item",
                { attrs: { label: "手机号：", prop: "tel" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入用户手机号" },
                    model: {
                      value: _vm.formData.tel,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "tel", $$v)
                      },
                      expression: "formData.tel",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "角色：", prop: "roleIdList" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { multiple: "", placeholder: "请选择角色" },
                      on: { change: _vm.getRoleValue },
                      model: {
                        value: _vm.formData.roleIdList,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "roleIdList", $$v)
                        },
                        expression: "formData.roleIdList",
                      },
                    },
                    _vm._l(_vm.roleArray, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "登录权限：", prop: "login" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.formData.login,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "login", $$v)
                        },
                        expression: "formData.login",
                      },
                    },
                    [
                      _c(
                        "el-checkbox",
                        {
                          attrs: {
                            label: "40175afc-01c1-4770-9fc8-dadd9dc9b368",
                          },
                        },
                        [_vm._v("登录可达鸭教务平台")]
                      ),
                      _c(
                        "el-checkbox",
                        {
                          attrs: {
                            label: "ee86f4cc-3ead-4bcd-8ce7-59b46a987500",
                          },
                        },
                        [_vm._v("登录可达鸭学习平台")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "可操作校区：" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "请选择校区",
                        filterable: "",
                        clearable: "",
                        multiple: "",
                        "collapse-tags": "",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                      model: {
                        value: _vm.formData.campusIdSet,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "campusIdSet", $$v)
                        },
                        expression: "formData.campusIdSet",
                      },
                    },
                    _vm._l(_vm.schoolArray, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "课程类别：" } },
                [
                  _c("el-tree", {
                    ref: "tree",
                    attrs: {
                      data: _vm.tableArr,
                      "show-checkbox": "",
                      "node-key": "id",
                      "check-strictly": true,
                      props: _vm.defaultProps,
                    },
                    on: { "check-change": _vm.handleCheckChange },
                  }),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              staticClass: "dialog-btn",
              attrs: { type: "primary" },
              on: { click: _vm.saveForm },
            },
            [_vm._v("确 定")]
          ),
          _c(
            "el-button",
            {
              staticClass: "dialog-btn",
              attrs: { type: "info" },
              on: { click: _vm.close },
            },
            [_vm._v("取 消")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }