<template>
  <div class="page-container">
    <el-form :inline="true">
      <div class="row-bg">
        <el-row :gutter="24">
          <el-col :lg="6" :md="24">
            <el-form-item label="用户姓名：">
              <el-input
                v-model="queryParam.name"
                @keyup.enter.native="search"
                placeholder="请输入"
                clearable
                class="w100"
              />
            </el-form-item>
          </el-col>
          <el-col :lg="6" :md="24">
            <el-form-item label="用户状态：">
              <el-select
                v-model="queryParam.state"
                class="w100"
                placeholder="请选择"
                clearable
                filterable
              >
                <el-option
                  v-for="item in stateArray"
                  :key="item.key"
                  :label="item.value"
                  :value="item.key"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :lg="6" :md="24">
            <el-form-item label="用户名：">
              <el-input
                v-model="queryParam.loginName"
                @keyup.enter.native="search"
                placeholder="请输入"
                clearable
                class="w100"
              />
            </el-form-item>
          </el-col>
          <template v-if="advanced">
            <el-col :lg="6" :md="24">
              <el-form-item label="组织结构：">
                <!-- 级联选择器 -->
                <el-cascader
                  v-model="queryParam.dtDpid"
                  class="w100"
                  clearable
                  :props="cascaderProps">
                </el-cascader>
              </el-form-item>
            </el-col>
            <el-col :lg="6" :md="24">
              <el-form-item label="角色名称：">
                <el-select
                  v-model="queryParam.dtPosition"
                  class="w100"
                  placeholder="请选择"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="item in positionArray"
                    :key="item.name"
                    :label="item.name"
                    :value="item.name"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :lg="6" :md="24">
              <el-form-item label="是否绑定：">
                <el-select
                  v-model="queryParam.hasBindDt"
                  class="w100"
                  placeholder="请选择"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="item in bindArray"
                    :key="item.key"
                    :label="item.value"
                    :value="item.key"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </template>
          <search-query
            @updateView="updateView"
            @clearQuery="queryParam = { isFinish: false }"
            @searchQuery="search"
          ></search-query>
        </el-row>
      </div>
    </el-form>

    <!-- 表格 -->
    <el-card shadow="never">
      <div slot="header" class="flex" style="align-items: center;">
        <span>员工管理</span>
        <div class="flex_1"></div>
        <el-button type="primary" size="mini" @click="bindUserId()"
          >更新组织结构</el-button
        >
        <el-button plain size="mini" @click="add()"
          >新增员工</el-button
        ></el-button>
        <text-button
          icon="el-icon-refresh"
          contentTit="刷新"
          @click.native="search"
        ></text-button>
      </div>

      <el-table-bar fixed :static="true">
        <el-table
          :data="dataList"
          fit
          :highlight-current-row="true"
        >
          <el-table-column label="用户名" align="center" prop="loginName"></el-table-column>
          <el-table-column label="姓名" align="center" prop="name"></el-table-column>
          <el-table-column label="手机号" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.tel">{{scope.row.tel}}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column label="创建时间" align="center" prop="createTime"></el-table-column>
          <el-table-column label="来源" align="center" prop="source"></el-table-column>
          <el-table-column label="状态" align="center">
            <template slot-scope="scope">
              <span
                class="span-state"
                :style="
                  'backgroundColor' + ':' + getStateNumColor(scope.row.state)
                "
              ></span>
              <span v-text="getStateNum(scope.row.state)"></span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="250">
            <template slot-scope="scope">
              <el-button type="text" @click="edit(scope.row)">编辑</el-button>
              <el-divider el-divider direction="vertical"></el-divider>
              <span class="iconfont icon-dingtalk-circle-fill pointer" @click="bind(scope.row)" :style="getStateClass(scope.row.dtUserId)"></span>
              <el-divider el-divider direction="vertical"></el-divider>
              <el-dropdown>
                <span class="el-dropdown-link">
                  更多<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="stop(scope.row)">停用</el-dropdown-item>
                  <el-dropdown-item @click.native="start(scope.row)">启用</el-dropdown-item>
                  <el-dropdown-item @click.native="del(scope.row)">删除</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </el-table-bar>

      <el-pagination
        class="fr-pagination"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pagination.currentPage"
        :page-sizes="pagination.pageSizes"
        :page-size="pagination.pageSize"
        :layout="pagination.layout"
        :total="pagination.total"
      ></el-pagination>
    </el-card>

    <!-- add & edit dialog -->
    <add-staff
      :show="staffDialog"
      :staffData="staffData"
      :schoolArray="schoolArray"
      @close="close"
      @addStaffData="addStaffData"
      @editStaffData="editStaffData"
    ></add-staff>

    <bind-ding-talk
      :show="bindDialog"
      @close="bindDialog = false"
      :rowObject="memberObject"
      :departmentList = "departmentList"
    ></bind-ding-talk>
  </div>
</template>

<script>
  import TextButton from '@/components/TextButton'
  import SearchQuery from '@/components/SearchQuery'
  import getStateNum from '@/mixins/getStateText'
  import getStateNumColor from '@/mixins/getStateColor'
  import addStaff from '@/components/system/addStaff'
  import { getchildDp, getListEmployee, addSta, editSta, bindUserId, getPositionList } from '@/api/system/staff'
  import { delUser, enableUser } from '@/api/system/public'
  import bindDingTalk from '@/components/system/bindDingTalk'
  import { getCampusAll } from '@/api/senate/campus'

  export default {
    components: { TextButton, SearchQuery, addStaff, bindDingTalk },
    props: {
      type: {
        default: '',
        type: String,
        require: true,
      },
    },
    mixins: [getStateNum, getStateNumColor],
    created() {
      this.getPositionList()
      this.getDeptManagerList()
      this.getListEmployee()
      this.getCampusAll()
    },
    data() {
      return {
        dataList: [],
        queryParam: {
          // 查询参数
          isFinish: false,
        },
        pagination: {
          currentPage: 1,
          pageSize: 10,
          pageSizes: [10, 20, 30, 40],
          layout: 'total, sizes, prev, pager, next, jumper',
          total: 0,
        },
        advanced: false,
        staffDialog: false,
        bindDialog: false,
        memberObject: {},
        staffData: null,
        stateArray: [{
          key: 1,
          value: '已启用'
        }, {
          key: 3,
          value: '已禁用'
        }],
        cascaderList: [],
        departmentList: [],
        positionArray: [],
        schoolArray: [], // 校区列表
        bindArray: [{
          key: true,
          value: '已绑定'
        }, {
          key: false,
          value: '未绑定'
        }],
        cascaderProps: {
          lazy: true,
          value:"id",
          label:"name",
          checkStrictly: true,
          lazyLoad(node, resolve) {
            // 如果是顶级的父节点
            if (node.level === 0) {
              getchildDp({}).then(res => {
                if (res.state === 'success') {
                  let nodeData = res.body
                  nodeData.push({
                    id: "-1",
                    name: "未分配"
                  })
                  return resolve(nodeData)
                }
              })
            } else {
              getchildDp({
                dpid: node.value
              }).then(res => {
                if (res.state === 'success') {
                  let nodeData = res.body
                  return resolve(nodeData)
                }
              })
            }
          },
        }
      }
    },
    methods: {
      async getCampusAll() {
        const res = await getCampusAll()
        this.schoolArray = res.body
      },

      async getPositionList() {
        const res = await getPositionList()
        this.positionArray = res.body
      },

      getDeptManagerList (dpid) {
        getchildDp({
          dpid: dpid ? dpid : ''
        }).then(res => {
          if (res.state === 'success') {
            if (!dpid) {
              let data = res.body
              // data.push({
              //   id: '-1',
              //   name: '未分配'
              // })
              this.departmentList = data
            }
          }
        })
      },

      getStateClass(state) {
        if(state) {
          return "color: #f5c319;"
        }
      },

      bind (row) {
        this.bindDialog = true
        this.memberObject = row
      },

      updateView(data) {
        this.advanced = data
      },

      // 添加/编辑
      add () {
        this.staffDialog = true
      },

      edit (row) {
        this.staffData = row
        this.staffDialog = true
      },

      bindUserId () {
        bindUserId({
        }).then(res => {
          if (res.state === 'success') {
            window.$msg('更新组织结构成功', 0)
          }
        })
      },

      // 停用
      async stop (row) {
        this.$confirm('是否停用当前用户', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
           await enableUser({
            userId: row.userId,
            status: 2
          })
          window.$msg('停用成功')
          this.getListEmployee()
        }).catch(() => {
          return false
        })
      },

      // 启用
      async start (row) {
        this.$confirm('是否启用当前用户', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          await enableUser({
            userId: row.userId,
            status: 1
          })
          window.$msg('启用成功')
          this.getListEmployee()
        }).catch(() => {
          return false
        })
      },
      // 删除
      del (row) {
        this.$confirm('是否删除当前用户', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(async () => {
            await delUser({
              userId: row.userId
            })
            window.$msg('删除成功')
            this.getListEmployee()
          })
          .catch(() => {
            return false
          })
      },

      // 关闭
      close () {
        this.staffDialog = false
        this.staffData = null
      },

      search() {
        this.pagination.currentPage = 1
        this.getListEmployee()
      },

      async getListEmployee () {
        const res = await getListEmployee({
          pageNum: this.pagination.currentPage,
          pageSize: this.pagination.pageSize,
          loginName: this.queryParam.loginName ? this.queryParam.loginName : null,
          name: this.queryParam.name ? this.queryParam.name : null,
          dtDpid: this.queryParam.dtDpid ? this.queryParam.dtDpid[this.queryParam.dtDpid.length-1] : null,
          state: this.queryParam.state ? this.queryParam.state : null,
          dtPosition: this.queryParam.dtPosition ? this.queryParam.dtPosition : null,
          hasBindDt: this.queryParam.hasBindDt !== null ? this.queryParam.hasBindDt : null
        })
        this.dataList = res.body.list
        this.pagination.total = res.body.total
      },

      // 分页
      handleCurrentChange(val) {
        this.pagination.currentPage = val
        this.getListEmployee()
      },
      handleSizeChange(val) {
        this.pagination.pageSize = val
        this.getListEmployee()
      },

      // 添加职工
      async addStaffData (data, seleCheck) {
        let addStaffData = {
          campusIdSet: data.campusIdSet,
          loginName: data.loginName,
          pwd: data.pwd,
          name: data.name,
          tel: data.tel || '',
          roleIdList: data.roleIdList.concat(data.login),
          catalogIdList: seleCheck ? seleCheck : []
        }
        await addSta({
          ...addStaffData
        })
        window.$msg('添加成功')
        this.close()
        this.getListEmployee()
      },

      // 编辑职工
      async editStaffData (data, seleCheck) {
        let editStaffData = {
          id: data.userId,
          campusIdSet: data.campusIdSet,
          loginName: data.loginName,
          password: data.pwd,
          name: data.name,
          tel: data.tel || '',
          roleIdList: data.roleIdList.concat(data.login),
          catalogIdList: seleCheck ? seleCheck : []
        }
        await editSta({
          ...editStaffData
        })
        window.$msg('编辑成功')
        this.close()
        this.getListEmployee()
      },

    },
  }
</script>

<style lang="scss" scoped>
@import '../../style/container.scss';

/deep/ .el-form--inline .el-form-item {
  margin-right: 0;
}
</style>
