var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c("el-form", { attrs: { inline: true } }, [
        _c(
          "div",
          { staticClass: "row-bg" },
          [
            _c(
              "el-row",
              { attrs: { gutter: 24 } },
              [
                _c(
                  "el-col",
                  { attrs: { lg: 6, md: 24 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "用户姓名：" } },
                      [
                        _c("el-input", {
                          staticClass: "w100",
                          attrs: { placeholder: "请输入", clearable: "" },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.search.apply(null, arguments)
                            },
                          },
                          model: {
                            value: _vm.queryParam.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParam, "name", $$v)
                            },
                            expression: "queryParam.name",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { lg: 6, md: 24 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "用户状态：" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "w100",
                            attrs: {
                              placeholder: "请选择",
                              clearable: "",
                              filterable: "",
                            },
                            model: {
                              value: _vm.queryParam.state,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryParam, "state", $$v)
                              },
                              expression: "queryParam.state",
                            },
                          },
                          _vm._l(_vm.stateArray, function (item) {
                            return _c("el-option", {
                              key: item.key,
                              attrs: { label: item.value, value: item.key },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { lg: 6, md: 24 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "用户名：" } },
                      [
                        _c("el-input", {
                          staticClass: "w100",
                          attrs: { placeholder: "请输入", clearable: "" },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.search.apply(null, arguments)
                            },
                          },
                          model: {
                            value: _vm.queryParam.loginName,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParam, "loginName", $$v)
                            },
                            expression: "queryParam.loginName",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.advanced
                  ? [
                      _c(
                        "el-col",
                        { attrs: { lg: 6, md: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "组织结构：" } },
                            [
                              _c("el-cascader", {
                                staticClass: "w100",
                                attrs: {
                                  clearable: "",
                                  props: _vm.cascaderProps,
                                },
                                model: {
                                  value: _vm.queryParam.dtDpid,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.queryParam, "dtDpid", $$v)
                                  },
                                  expression: "queryParam.dtDpid",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { lg: 6, md: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "角色名称：" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "w100",
                                  attrs: {
                                    placeholder: "请选择",
                                    clearable: "",
                                    filterable: "",
                                  },
                                  model: {
                                    value: _vm.queryParam.dtPosition,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.queryParam,
                                        "dtPosition",
                                        $$v
                                      )
                                    },
                                    expression: "queryParam.dtPosition",
                                  },
                                },
                                _vm._l(_vm.positionArray, function (item) {
                                  return _c("el-option", {
                                    key: item.name,
                                    attrs: {
                                      label: item.name,
                                      value: item.name,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { lg: 6, md: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "是否绑定：" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "w100",
                                  attrs: {
                                    placeholder: "请选择",
                                    clearable: "",
                                    filterable: "",
                                  },
                                  model: {
                                    value: _vm.queryParam.hasBindDt,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.queryParam, "hasBindDt", $$v)
                                    },
                                    expression: "queryParam.hasBindDt",
                                  },
                                },
                                _vm._l(_vm.bindArray, function (item) {
                                  return _c("el-option", {
                                    key: item.key,
                                    attrs: {
                                      label: item.value,
                                      value: item.key,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
                _c("search-query", {
                  on: {
                    updateView: _vm.updateView,
                    clearQuery: function ($event) {
                      _vm.queryParam = { isFinish: false }
                    },
                    searchQuery: _vm.search,
                  },
                }),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-card",
        { attrs: { shadow: "never" } },
        [
          _c(
            "div",
            {
              staticClass: "flex",
              staticStyle: { "align-items": "center" },
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c("span", [_vm._v("员工管理")]),
              _c("div", { staticClass: "flex_1" }),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      return _vm.bindUserId()
                    },
                  },
                },
                [_vm._v("更新组织结构")]
              ),
              _c(
                "el-button",
                {
                  attrs: { plain: "", size: "mini" },
                  on: {
                    click: function ($event) {
                      return _vm.add()
                    },
                  },
                },
                [_vm._v("新增员工")]
              ),
              _c("text-button", {
                attrs: { icon: "el-icon-refresh", contentTit: "刷新" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.search.apply(null, arguments)
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "el-table-bar",
            { attrs: { fixed: "", static: true } },
            [
              _c(
                "el-table",
                {
                  attrs: {
                    data: _vm.dataList,
                    fit: "",
                    "highlight-current-row": true,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "用户名",
                      align: "center",
                      prop: "loginName",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "姓名", align: "center", prop: "name" },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "手机号", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.tel
                              ? _c("span", [_vm._v(_vm._s(scope.row.tel))])
                              : _c("span", [_vm._v("--")]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "创建时间",
                      align: "center",
                      prop: "createTime",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "来源", align: "center", prop: "source" },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "状态", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", {
                              staticClass: "span-state",
                              style:
                                "backgroundColor" +
                                ":" +
                                _vm.getStateNumColor(scope.row.state),
                            }),
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.getStateNum(scope.row.state)
                                ),
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作", align: "center", width: "250" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.edit(scope.row)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            ),
                            _c("el-divider", {
                              attrs: {
                                "el-divider": "",
                                direction: "vertical",
                              },
                            }),
                            _c("span", {
                              staticClass:
                                "iconfont icon-dingtalk-circle-fill pointer",
                              style: _vm.getStateClass(scope.row.dtUserId),
                              on: {
                                click: function ($event) {
                                  return _vm.bind(scope.row)
                                },
                              },
                            }),
                            _c("el-divider", {
                              attrs: {
                                "el-divider": "",
                                direction: "vertical",
                              },
                            }),
                            _c(
                              "el-dropdown",
                              [
                                _c(
                                  "span",
                                  { staticClass: "el-dropdown-link" },
                                  [
                                    _vm._v(" 更多"),
                                    _c("i", {
                                      staticClass:
                                        "el-icon-arrow-down el-icon--right",
                                    }),
                                  ]
                                ),
                                _c(
                                  "el-dropdown-menu",
                                  {
                                    attrs: { slot: "dropdown" },
                                    slot: "dropdown",
                                  },
                                  [
                                    _c(
                                      "el-dropdown-item",
                                      {
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.stop(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v("停用")]
                                    ),
                                    _c(
                                      "el-dropdown-item",
                                      {
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.start(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v("启用")]
                                    ),
                                    _c(
                                      "el-dropdown-item",
                                      {
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.del(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v("删除")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-pagination", {
            staticClass: "fr-pagination",
            attrs: {
              background: "",
              "current-page": _vm.pagination.currentPage,
              "page-sizes": _vm.pagination.pageSizes,
              "page-size": _vm.pagination.pageSize,
              layout: _vm.pagination.layout,
              total: _vm.pagination.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c("add-staff", {
        attrs: {
          show: _vm.staffDialog,
          staffData: _vm.staffData,
          schoolArray: _vm.schoolArray,
        },
        on: {
          close: _vm.close,
          addStaffData: _vm.addStaffData,
          editStaffData: _vm.editStaffData,
        },
      }),
      _c("bind-ding-talk", {
        attrs: {
          show: _vm.bindDialog,
          rowObject: _vm.memberObject,
          departmentList: _vm.departmentList,
        },
        on: {
          close: function ($event) {
            _vm.bindDialog = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }