<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    @open="openDialog"
    width="50%"
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span v-text="StuDialogTitle"></span>
    </div>
    <el-form ref="formData" :model="formData" :rules="rules" label-width="120px">
      <el-row>
        <el-form-item label="用户名：" prop="loginName">
          <el-input v-model="formData.loginName" placeholder="请输入用户名"></el-input>
        </el-form-item>
        <el-form-item label="姓名：" prop="name">
          <el-input v-model="formData.name" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <template v-if="staffData === null">
          <el-form-item label="初始密码：" prop="pwd">
            <el-input
              v-model="formData.pwd"
              placeholder="请输入初始密码（密码包含大小写字母、特殊符号、数字三种即可）"
              show-password
              auto-complete="new-password"
            ></el-input>
          </el-form-item>
        </template>
        <template v-else>
          <el-form-item label="修改密码：">
            <el-input
              v-model="formData.pwd"
              placeholder="请输入新密码、为空密码不变"
              show-password
              auto-complete="new-password"
            ></el-input>
          </el-form-item>
        </template>
        <el-form-item label="手机号：" prop="tel">
          <el-input v-model="formData.tel" placeholder="请输入用户手机号"></el-input>
        </el-form-item>
        <el-form-item label="角色：" prop="roleIdList">
          <el-select
            v-model="formData.roleIdList"
            multiple
            placeholder="请选择角色"
            @change="getRoleValue"
            style="width: 100%"
          >
            <el-option v-for="item in roleArray" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="登录权限：" prop="login">
          <el-checkbox-group v-model="formData.login">
            <el-checkbox label="40175afc-01c1-4770-9fc8-dadd9dc9b368">登录可达鸭教务平台</el-checkbox>
            <el-checkbox label="ee86f4cc-3ead-4bcd-8ce7-59b46a987500">登录可达鸭学习平台</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="可操作校区：">
          <el-select v-model="formData.campusIdSet" placeholder="请选择校区" filterable clearable multiple collapse-tags @change="$forceUpdate()">
            <el-option
              v-for="item in schoolArray"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="课程类别：">
          <el-tree
            ref="tree"
            :data="tableArr"
            show-checkbox
            node-key="id"
            :check-strictly="true"
            @check-change="handleCheckChange"
            :props="defaultProps"
          ></el-tree>
        </el-form-item>
      </el-row>
    </el-form>
    <el-row slot="footer">
      <el-button type="primary" @click="saveForm" class="dialog-btn">确 定</el-button>
      <el-button @click="close" type="info" class="dialog-btn">取 消</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
import { getRolesAll } from '@/api/system/role'
import { getUserInfo } from '@/api/system/public'
import { courseCatalog } from '@/api/research/curriculum'

export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    staffData: {
      default: () => {
        return {}
      },
      type: Object
    },
    schoolArray: {
      default: () => {
        return []
      },
      type: Array
    }
  },
  watch: {
    show () {
      if (this.show) {
        if (this.$refs.formData) {
          this.$refs.formData.resetFields()
        }
      }
    }
  },
  data () {
    return {
      StuDialogTitle: '',
      formData: {
        roleIdList: [],
        login: [],
      },
      roleArray: [],
      rules: {
        loginName: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
        ],
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        pwd: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        roleIdList: [
          { required: true, message: '请选择角色', trigger: 'blur' }
        ],
        login: [
          { required: true, message: '请选登录权限', trigger: 'blur' }
        ]
      },
      data: [],
      defaultProps: {
        children: 'children',
        label: 'title'
      },
      tableList: [],
      tableArr: [], // 表格重组数据
      cascaderList: [], // 选择器重组数据
      seleCheck: [],
    }
  },
  methods: {
    // 获取课程类别
    async courseCatalog () {
      this.tableArr = []
      this.cascaderList = []
      const res = await courseCatalog({
      })
      this.tableList = res.body
      this.buildTree(res.body)
      this.cascaderList = [...this.tableArr]
    },

    // 构建 树形数据
    buildTree (list) {
      let dataList = []
      for (let index in list) {
        dataList[list[index].id] = list[index]
      }
      for (let i in dataList) {
        if (dataList[i].pid) {
          if (!dataList[dataList[i].pid].children) {
            dataList[dataList[i].pid].children = []
          }
          dataList[dataList[i].pid].children.push(dataList[i])
        } else {
          this.tableArr.push(dataList[i])
        }
      }
    },

    openDialog () {
      this.$nextTick(() => {
        this.courseCatalog()
        this.getRoleByPage()
        this.formData = {
          roleIdList: [],
          login: []
        }
        this.$refs.tree.setCheckedKeys([])
        if (this.staffData === null) {
          this.StuDialogTitle = '新增员工'
        } else {
          this.StuDialogTitle = '编辑员工'
          this.formData.userId = this.staffData.userId
          this.formData.loginName = this.staffData.loginName
          this.formData.name = this.staffData.name
          this.formData.tel = this.staffData.tel
          this.getUserInfo()
        }
      })
    },

    // 关闭
    close () {
      this.$emit('close')
    },

    handleCheckChange (data, checked, treeChecked) {
      let res = this.$refs.tree.getCheckedNodes()
      let arr = []
      res.forEach(item => {
        arr.push(item.id)
      })
      this.seleCheck = arr
    },

    // 获取角色列表
    getRoleByPage () {
      getRolesAll()
        .then(res => {
          if (res.state === 'success') {
            this.roleArray = res.body
            this.roleArray.forEach((item, idx) => {
              // if (this.staffData === null) {
              if (item.id === '40175afc-01c1-4770-9fc8-dadd9dc9b368' || item.id === 'ee86f4cc-3ead-4bcd-8ce7-59b46a987500') {
                this.roleArray.splice(idx, 1)
              }
              // }
            })
          } else {
            window.$msg(res.errMsg, 2)
          }
        }).catch(err => {
          console.log('获取所有角色', err)
          window.$msg('接口请求失败', 1)
        })
    },

    // 获取用户信息
    async getUserInfo () {
      const res = await getUserInfo({
        userId: this.staffData.userId
      }).then(res => {
        let roleInfoList = res.body.roleInfoList
        let roleArray = []
        roleInfoList.map((item, index) => {
          if (item.id === '40175afc-01c1-4770-9fc8-dadd9dc9b368' || item.id === 'ee86f4cc-3ead-4bcd-8ce7-59b46a987500') {
            this.formData.login.push(item.id)
          } else {
            roleArray.push(item.id)
          }
        })
        this.formData.roleIdList = roleArray
        this.formData.campusIdSet = res.body.campusInfoList.length === 0 ? null : res.body.campusInfoList[0].campusIdSet // ？ 用户所属校区为什么是一个数组
        if (res.body.campusInfoList.lenght !== 0) {
          this.formData.campusIdSet = []
          res.body.campusInfoList.forEach((ele, item) => {
            this.formData.campusIdSet.push(ele.campus_id)
          });
        }
        this.$refs.tree.setCheckedKeys([...res.body.courseCatalogIdList])
        this.$forceUpdate()
      })
    },

    getRoleValue (val) {
      this.formData.roleIdList = val
      this.$forceUpdate()
    },

    // 保存
    saveForm () {
      this.$refs['formData'].validate(valid => {
        if (valid) {
          if (this.staffData === null) {
            this.$emit('addStaffData', { ...this.formData }, this.seleCheck)
          } else {
            this.$emit('editStaffData', {...this.formData}, this.seleCheck)
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../style/dialog.scss";
</style>
