<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    @open="openDialog"
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>绑定钉钉用户</span>
      <span style="font-size: 14px;margin-left: 20px;color: #DD3329;">如果没有想要绑定的用户请返回上个页面，更新组织架构后在尝试查找</span>
    </div>
    <el-row style="display: flex;align-items: center">
      <el-col  :span="3" style="margin-left: 25px;">
        <el-input style="width: 150px;" placeholder="请输入姓名" v-model="dingName"></el-input>
      </el-col>

      <el-col :span="4" :offset="2">
        <el-cascader :props="propstion" v-model="departmentId" placeholder="请选择员工部门"></el-cascader>

      </el-col>
      <el-col :span="6" :offset="1">
        <el-button  type="primary" size="small " @click="gotoQuery">查询</el-button>
        <el-button type="primary" size="small "  style="margin-left: 30px;" @click="reset()">重置</el-button>
      </el-col>
    </el-row>
    <el-table
      style="margin-top: 20px;"
      :data="dataList"
      border
      highlight-current-row
      class="tb-list"
    >
      <el-table-column label="姓名" align="center" prop="name"></el-table-column>
      <el-table-column label="职位" align="center" prop="position"></el-table-column>
      <el-table-column label="工号" align="center" prop="jobnumber"></el-table-column>
      <el-table-column label="操作" align="center" width="150">
        <template slot-scope="scope">
          <el-button type="primary" @click="dingtalkBind(scope.row)" size="mini">绑定</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.currentPage"
      :page-sizes="pagination.pageSizes"
      :page-size="pagination.pageSize"
      :layout="pagination.layout"
      :total="pagination.total"
    ></el-pagination>
  </el-dialog>
</template>

<script>
  import {dingtalkBind ,getDingtalkUserList,getchildDp} from '@/api/system/staff'
export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    rowObject: {
      default: () => {
        return {}
      },
      type: Object
    },
    departmentList: {
      default: () => {
        return []
      },
      type: Array
    }
  },
  data(){
    return {
      dingName:"",
      departmentId:"",
      dataList:[],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 30, 50, 100],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      },
      propstion: {
        lazy: true,
        value:"id",
        label:"name",
        checkStrictly: true,
        lazyLoad(node, resolve) {
          // 如果是顶级的父节点
          if (node.level === 0) {
            getchildDp({}).then(res => {
              if (res.state === 'success') {
                let nodeData = res.body
                return resolve(nodeData)
              }
            })
          } else if (node.level === 1) {

            getchildDp({
              dpid: node.value
            }).then(res => {
              if (res.state === 'success') {
                let nodeData = res.body
                nodeData.push({
                  id: "-1",
                  name: "其他"
                })
                return resolve(nodeData)
              }
            })
          } else if (node.level > 1) {
            getchildDp({
              dpid: node.value
            }).then(res => {
              if (res.state === 'success') {
                let nodeData = res.body
                return resolve(nodeData)
              }
            })
          }
        },
      }
    };
  },
  methods: {
    gotoQuery() {
      this.pagination.currentPage = 1
      this.getDingtalkUserList();
    },
    reset(){
      this.dingName = ""
      this.departmentId = ""
    },
    openDialog () {
      this.dingName = ""
      this.departmentId = ""
      this.getDingtalkUserList();
    },
    async dingtalkBind(row) {
      const response = await dingtalkBind({
        tmsUserId:this.rowObject.userId,
        dtUserId:row.userid
      })
      if(response && response.state === "success") {
        $msg("绑定成功")
      }else {
        $msg(response.errMsg,2)
      }
    },
    async getDingtalkUserList() {
      const response = await getDingtalkUserList({
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        needCount: true,
        name:this.dingName,
        dpid:this.departmentId[this.departmentId.length - 1]
      })
      this.dataList = response.body.list
      this.pagination.total =  response.body.total
    },
    // 分页
    handleSizeChange (val) {
      this.pagination.pageSize = val
      this.getDingtalkUserList()
    },
    handleCurrentChange (val) {
      this.pagination.currentPage = val
      this.getDingtalkUserList()
    },
    // 关闭
    close () {
      this.$emit('close')
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../../style/dialog.scss";
@import '../../../style/table.scss';
</style>
